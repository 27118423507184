document.addEventListener("DOMContentLoaded", function () {
    const popup = document.querySelector('.newsletter-popup');
    if (!popup) return;

    const locationInput = popup.querySelector('#location');
    const useLocation = popup.querySelector('#use-location');
    const submitButton = popup.querySelector('button[type="submit"]');
    const locationSpan = document.querySelectorAll(".edit-location span");
    const autocompleteResults = popup.querySelector(".input-wrapper ul.autocomplete-results");
    const editLocationTitle = document.querySelectorAll(".edit-location-title");

    const errorsGeolocation = {
        "address_city_unavailable" : {
            it: "Errore nel recupero della città dall'indirizzo",
            en: "Error getting city from address"
        },
        "latitude_longitude_unavailable" : {
            it: "Errore nel recupero della latitudine e longitudine",
            en: "Error getting latitude and longitude"
        },
        "geolocation_unavailable" : {
            it: "Errore nel recupero della posizione",
            en: "Error retrieving location"
        },
        "geolocation_unsupported" : {
            it: "La geolocalizzazione non è supportata da questo browser",
            en: "Geolocation is not supported by this browser."
        },
        "geolocation_outside_italy" : {
            it: "Questo servizio funziona solo in Italia. La tua posizione attuale è fuori dell'Italia.",
            en: "This service only works in Italy. Your current location is outside Italy."
        },
        "error_updating_location" : {
            it: "Errore nell'aggiornamento della posizione",
            en: "Error updating location"
        }
    }

    let sessionToken = new google.maps.places.AutocompleteSessionToken();
    let autocompleteService = new google.maps.places.AutocompleteService();

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const showSnackbar = (message, type = 'error') => {
        Wolmart.SnackbarNotificationPopup.open({
            message,
            type
        });
    };

    const updateAutocompleteResults = (predictions) => {
        autocompleteResults.innerHTML = '';
        predictions.forEach(prediction => {
            const li = document.createElement('li');
            li.textContent = prediction.description;
            li.addEventListener('click', () => {
                locationInput.value = prediction.description;
                autocompleteResults.innerHTML = '';
            });
            autocompleteResults.appendChild(li);
        });
    };

    const getPlacePredictions = (inputText) => {
        if (inputText.length > 2) {
            autocompleteService.getPlacePredictions({
                input: inputText,
                types: ['geocode'],
                componentRestrictions: { country: 'it' },
                sessionToken,
                language: Wolmart.lang
            }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    updateAutocompleteResults(predictions);
                }
            });
        } else {
            autocompleteResults.innerHTML = '';
        }
    };

    locationInput.addEventListener('input', debounce(() => {
        useLocation.checked = false;
        getPlacePredictions(locationInput.value);
    }, 150));

    const submitForm = async () => {
        try {
            const predictions = await new Promise((resolve, reject) => {
                autocompleteService.getPlacePredictions({
                    input: locationInput.value,
                    types: ['geocode'],
                    componentRestrictions: { country: 'it' },
                    language: Wolmart.lang
                }, (predictions, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        resolve(predictions);
                    } else {
                        reject("No valid location found for Italy.");
                    }
                });
            });

            const prediction = predictions[0];
            locationInput.style.border = '1px solid green';
            locationInput.value = prediction.description;

            const latLongPrediction = await getLatitudeAndLongitude(prediction.description);
            const city = await getCityFromAddress(prediction.description);

            locationSpan.forEach(span => span.textContent = city);

            const locationInfo = {
                lat: latLongPrediction.lat,
                lng: latLongPrediction.lng,
                place: city
            };

            editLocationTitle.forEach(title => {
                title.innerHTML = Wolmart.lang === "it" ? "Risultati per: " : "Results for: ";
            });

            var xhr = new XMLHttpRequest();
            xhr.open("POST", "/api/user/location/updateLocationInfo.php", true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onreadystatechange = function () {
                if (xhr.readyState !== 4) return;

                if (xhr.status !== 200) {
                    showSnackbar(errorsGeolocation.error_updating_location[Wolmart.lang], 'error');
                } else {
                    const response = JSON.parse(xhr.responseText);
            
                    if (!response.success) {
                        showSnackbar(errorsGeolocation.error_updating_location[Wolmart.lang], 'error');
                    } else {
                        Wolmart.localStorage.setItem('reload', 'true');
                    }
                }
            
                Wolmart.popup.close();
            }
            xhr.send(JSON.stringify(locationInfo));
            
        } catch (error) {
            locationInput.style.border = '1px solid red';
            showSnackbar(error, 'error');
        }
    };

    submitButton.addEventListener('click', (e) => {
        e.preventDefault();
        submitForm();
    });

    const getCityFromAddress = async (address) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyD6hLgs-G3sm3uNrfRtIsQnIcLSPTLWiV4&language=${Wolmart.lang}`);
        const data = await response.json();

        if (data.status !== "OK" || data.results.length === 0) throw new Error(errorsGeolocation.address_city_unavailable[Wolmart.lang]);

        for (const result of data.results) {
            for (const component of result.address_components) {
                if (component.types.includes("locality")) return component.long_name;
                if (component.types.includes("administrative_area_level_3")) return component.long_name;
                if (component.types.includes("sublocality_level_1")) return component.long_name;
            }
        }

        return data.results.length > 0 ? data.results[0].formatted_address : "Italia";
    };

    const getLatitudeAndLongitude = async (address) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyD6hLgs-G3sm3uNrfRtIsQnIcLSPTLWiV4&language=${Wolmart.lang}`);
        const data = await response.json();

        if (data.status !== "OK" || data.results.length === 0) throw new Error(errorsGeolocation.latitude_longitude_unavailable[Wolmart.lang]);

        return data.results[0].geometry.location;
    };

    const showPosition = (position) => {
        const { latitude, longitude } = position.coords;

        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD6hLgs-G3sm3uNrfRtIsQnIcLSPTLWiV4&language=${Wolmart.lang}`)
            .then(response => response.json())
            .then(data => {
                if (data.status !== "OK") throw new Error("Error retrieving location");
                const address = data.results[0].formatted_address;

                const country = data.results[0].address_components.find(comp => comp.types.includes("country")).short_name;
                if (country !== 'IT') {
                    showSnackbar(errorsGeolocation.geolocation_outside_italy[Wolmart.lang], 'error');
                    useLocation.checked = false;
                    return;
                }

                locationInput.value = address;
            })
            .catch(error => showSnackbar(error.message, 'error'));
    };

    useLocation.addEventListener('change', function () {
        if (!this.checked) return;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, () => showSnackbar(errorsGeolocation.geolocation_unsupported[Wolmart.lang], 'error'));
        }
    });
});
